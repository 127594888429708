<template>
    <b-tab :active="isActive">
        <template #title><i :class="iconClass"></i>{{ title }}</template>
        <b-row>
            <b-col cols="12" class="d-flex align-items-end">
                <p class="font-weight-normal mr-2 mb-0">I</p>
                <ValidationProvider name="agree" rules="" v-slot="{ valid, errors }">
                    <b-form-group class="mb-0">
                        <b-form-input type="text" v-model="form.agree" :state="errors[0] ? false : null"
                            class="border-top-0 border-left-0 border-right-0 rounded-0 border-dark no-focus" />
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                    </b-form-group>
                </ValidationProvider>
                <p class="font-weight-normal ml-2 mb-0">{{ $t('inspection_agree_text') }}</p>
            </b-col>
            <b-col class="mt-3">
                <b-card-group deck>
                    <b-card class="border-0" no-body>
                        <template #header>
                            <div class="font-weight-bold">{{ $t('move_in_inspection') }}</div>
                        </template>
                        <b-card-body class="px-0">
                            <ValidationProvider name="island_condo_officer" rules="required" v-slot="{ valid, errors }">
                                <b-form-group :label="$t('island_condo_officer')">
                                    <select-date v-model="form.island_condo_officer"
                                        :state="errors[0] ? false : null" />
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="student_tenant" rules="required" v-slot="{ valid, errors }">
                                <b-form-group :label="$t('student_tenant')">
                                    <select-date v-model="form.student_tenant" :state="errors[0] ? false : null" />
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="student_signature" rules="required" v-slot="{ valid, errors }">
                                <b-form-group :label="$t('student_signature')">
                                    <b-form-input type="text" v-model="form.student_signature"
                                        :state="errors[0] ? false : null" />
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                </b-form-group>
                            </ValidationProvider>
                        </b-card-body>
                    </b-card>
                    <b-card class="border-0" no-body>
                        <template #header>
                            <div class="font-weight-bold">{{ $t('move_out_inspection') }}</div>
                        </template>
                        <b-card-body class="px-0">
                            <ValidationProvider name="island_condo_officer_mo" rules="required"
                                v-slot="{ valid, errors }">
                                <b-form-group :label="$t('island_condo_officer')">
                                    <select-date v-model="form.island_condo_officer_mo"
                                        :state="errors[0] ? false : null" />
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="student_tenant_mo" rules="required" v-slot="{ valid, errors }">
                                <b-form-group :label="$t('student_tenant')">
                                    <select-date v-model="form.student_tenant_mo" :state="errors[0] ? false : null" />
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="student_signature_mo" rules="required" v-slot="{ valid, errors }">
                                <b-form-group :label="$t('student_signature')">
                                    <b-form-input type="text" v-model="form.student_signature_mo"
                                        :state="errors[0] ? false : null" />
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                </b-form-group>
                            </ValidationProvider>
                        </b-card-body>
                    </b-card>
                </b-card-group>
            </b-col>
        </b-row>
    </b-tab>
</template>

<script>
// Other
import { ValidationProvider } from 'vee-validate'

export default {
    props: {
        isActive: Boolean,
        form: Object,
        iconClass: String,
        title: String,
    },
    components: {
        ValidationProvider
    }
}
</script>
<style>
.no-focus:focus {
    outline: none;
    box-shadow: none;
}
</style>