<template>
    <b-tabs content-class="py-5 position-relative" class="mt-5" v-model="localTabIndex" :key="renderCount">
        <div class="tabs-bottom-line"></div>
        <approval-tab v-for="(tab, index) in tabs" :key="index" :isActive="localTabIndex === index"
            :iconClass="tab.iconClass" :title="tab.title" :items="tab.items" :rowClass="tab.rowClass" :fields="fields"
            :form="form" ref="tab" />
        <inspection-tab :isActive="localTabIndex === 3" iconClass="ri-search-line top-plus-2" :title="$t('inspection')"
            :form="form" />
    </b-tabs>
</template>

<script>
//Page
import ApprovalTab from "./ApprovalTab.vue"
import InspectionTab from "./InspectionTab.vue"

export default {
    props: {
        tabIndex: Number,
        renderCount: Number,
        bedroomItems: Array,
        bathroomItems: Array,
        commonAreasItems: Array,
        fields: Array,
        form: Object
    },
    components: {
        ApprovalTab,
        InspectionTab
    },
    data() {
        return {
            localTabIndex: this.tabIndex
        };
    },
    watch: {
        tabIndex(newIndex) {
            this.localTabIndex = newIndex;
        },
        localTabIndex(newIndex) {
            this.$emit('update:tabIndex', newIndex);
        }
    },
    computed: {
        tabs() {
            return [
                { title: this.$t('bedroom'), iconClass: 'ri-hotel-line top-plus-2', items: this.bedroomItems, rowClass: this.rowClass },
                { title: this.$t('bathroom'), iconClass: 'ri-home-line top-plus-2', items: this.bathroomItems, rowClass: this.rowClass },
                { title: this.$t('common_areas'), iconClass: 'ri-building-line top-plus-2', items: this.commonAreasItems, rowClass: this.rowClass }
            ];
        }
    },
    methods: {
        rowClass(item) {
            return item.future_main ? 'font-weight-bold' : '';
        },
    }
};
</script>
