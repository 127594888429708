<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('island_condo_checklist')" :isFilter="false">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('island_condo_checklist')" :isFilter="false">
                </HeaderMobile>
            </template>
            <ValidationObserver ref="formModalValidate">
                <form-fields :form="form" />
                <tab-section :tabIndex="tabIndex" :renderCount="renderCount" :bedroomItems="bedroomItems"
                    :bathroomItems="bathroomItems" :commonAreasItems="commonAreasItems" :fields="fields" :form="form" />
                <b-row>
                    <b-col cols="12" class="mb-5">
                        <b-button v-if="tabIndex < 3" @click="next" type="button" variant="primary"
                            class="btn-sm float-right">
                            {{ $t('next') }}
                        </b-button>
                        <b-button v-if="tabIndex === 3" @click="send" type="button" variant="primary"
                            class="btn-sm float-right">
                            {{ $t('send') }}
                        </b-button>
                    </b-col>
                </b-row>
            </ValidationObserver>
        </app-layout>
    </div>
</template>
<script>

// Template
import AppLayout from "@/layouts/AppLayout"
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'

// Page
import TabSection from "./TabSection.vue";
import FormFields from "./FormFields.vue";

//Other
import { ValidationObserver } from "vee-validate";

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        ValidationObserver,
        FormFields,
        TabSection,
    },
    data() {
        return {
            form: {},
            renderCount: 0,
            tabIndex: 0,
            items: [],
            fields: [
                { key: 'future_name', label: '', tdClass: 'text-left' },
                { key: 'move_in', label: 'Move-In' },
                { key: 'move_out', label: 'Move-Out' },
                { key: 'note', label: 'Note' },
            ]
        };
    },
    metaInfo() {
        return {
            title: this.$t("island_condo_checklist")
        }
    },
    methods: {
        getData() {
            const bedroomItems = [
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'ITEMS PER RESIDENT', future_main: true, note: 'Test Note', tabBedroom: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Bed', tabBedroom: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Linen / Top Sheet', tabBedroom: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Pillow', tabBedroom: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Pillowcase', tabBedroom: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Blanket', tabBedroom: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Full Size Mirror', tabBedroom: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Dressers', tabBedroom: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'GENERAL ITEMS', future_main: true, tabBedroom: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Floor', tabBedroom: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Walls / Ceiling', tabBedroom: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Doors', tabBedroom: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Windows', tabBedroom: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Window Blinds', tabBedroom: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Closets', tabBedroom: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Light Fixtures / Bulbs', tabBedroom: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Outlets and Switches', tabBedroom: true },
            ];

            const bathroomItems = [
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Floor', tabBathroom: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Walls / Ceiling', tabBathroom: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Doors', tabBathroom: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Shower / Tub', tabBathroom: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Mirror', tabBathroom: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Light Fixtures / Bulbs', tabBathroom: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Outlets and Switches', tabBathroom: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Sink / Plumbing', tabBathroom: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Water Temperature', tabBathroom: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Fan', tabBathroom: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Toilet', tabBathroom: true, future_main: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Seat', tabBathroom: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Flush', tabBathroom: true },
            ];

            const commonAreasItems = [
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'KITCHEN', future_main: true, tabCommonAreas: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Kitchenware Appliances', tabCommonAreas: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Microwave', tabCommonAreas: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Refrigerator', tabCommonAreas: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Stove / Oven', tabCommonAreas: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Dishwater', tabCommonAreas: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Floor', tabCommonAreas: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Wall / Ceiling', tabCommonAreas: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Counter', tabCommonAreas: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Cabinets / Drawers', tabCommonAreas: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Light Fixtures / Bulbs', tabCommonAreas: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Outlets and Switches', tabCommonAreas: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Sink / Plumbing', tabCommonAreas: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'MECHANICAL', future_main: true, tabCommonAreas: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'A/C and Heate', tabCommonAreas: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Washer / Dryer', tabCommonAreas: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'Smoke Detector', tabCommonAreas: true },
                { moveStatusIn: 'out', moveStatusOut: 'out', future_name: 'OTHER', future_main: true, tabCommonAreas: true },
            ];

            this.items.push(...bedroomItems, ...bathroomItems, ...commonAreasItems);
        },
        next() {
            if (this.tabIndex < 3) {
                this.tabIndex++;
                this.renderCount++;
            }
        },
        send() {
            //Send Form
        }
    },
    computed: {
        bedroomItems() {
            return this.items.filter(item => item.tabBedroom);
        },
        bathroomItems() {
            return this.items.filter(item => item.tabBathroom);
        },
        commonAreasItems() {
            return this.items.filter(item => item.tabCommonAreas);
        }
    },
    created() {
        this.getData();
    }
}
</script>
<style></style>
