<template>
    <b-row>
        <b-col cols="12" md="6">
            <ValidationProvider name="student_name" rules="required" v-slot="{ valid, errors }">
                <b-form-group :label="$t('student_name')" :disabled="true">
                    <b-form-input type="text" v-model="form.student_name" :state="errors[0] ? false : null" />
                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                </b-form-group>
            </ValidationProvider>
        </b-col>
        <b-col cols="12" md="6">
            <ValidationProvider name="student_type" rules="required" v-slot="{ valid, errors }">
                <b-form-group :label="$t('student_type')" :disabled="true">
                    <b-form-input type="text" v-model="form.student_type" :state="errors[0] ? false : null" />
                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                </b-form-group>
            </ValidationProvider>
        </b-col>
        <b-col cols="12" md="6">
            <ValidationProvider name="building_room" rules="required" v-slot="{ valid, errors }">
                <b-form-group :label="$t('building_room')" :disabled="true">
                    <b-form-input type="text" v-model="form.building_room" :state="errors[0] ? false : null" />
                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                </b-form-group>
            </ValidationProvider>
        </b-col>
        <b-col cols="12" md="6">
            <ValidationProvider name="room_type" rules="required" v-slot="{ valid, errors }">
                <b-form-group :label="$t('room_type')" :disabled="true">
                    <b-form-input type="text" v-model="form.room_type" :state="errors[0] ? false : null" />
                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                </b-form-group>
            </ValidationProvider>
        </b-col>
        <b-col cols="12" md="6">
            <ValidationProvider name="move_in" rules="required" v-slot="{ valid, errors }">
                <b-form-group :label="$t('move_in')">
                    <select-date v-model="form.move_in" :state="errors[0] ? false : null" />
                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                </b-form-group>
            </ValidationProvider>
        </b-col>
        <b-col cols="12" md="6">
            <ValidationProvider name="move_out" rules="required" v-slot="{ valid, errors }">
                <b-form-group :label="$t('move_out')">
                    <select-date v-model="form.move_out" :state="errors[0] ? false : null" />
                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                </b-form-group>
            </ValidationProvider>
        </b-col>
        <b-col cols="12">
            <p class="font-weight-normal">{{ $t('island_condo_checklist_text') }}</p>
        </b-col>
    </b-row>
</template>
<script>
// Other
import { ValidationProvider } from 'vee-validate'

export default {
    props: {
        form: Object
    },
    components: {
        ValidationProvider
    }
};
</script>