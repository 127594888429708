<template>
    <b-tab :active="isActive">
        <template #title><i :class="iconClass"></i>{{ title }}</template>
        <b-row>
            <b-col cols="12" lg="9" xxl="10">
                <div class="mb-0 table-dropdown border rounded table-responsive"
                    style="height: calc(100vh - 280px); min-height: 300px; overflow-y: scroll">
                    <b-table table-class="text-center" striped hover :items="items" :tbody-tr-class="rowClass"
                        :fields="fields">
                        <template #cell(move_in)="row">
                            <b-button-group size="sm">
                                <b-button :variant="row.item.moveStatusIn === 'in' ? 'success' : 'secondary'"
                                    @click="row.item.moveStatusIn = 'in'">Yes</b-button>
                                <b-button :variant="row.item.moveStatusIn === 'out' ? 'danger' : 'secondary'"
                                    @click="row.item.moveStatusIn = 'out'">No</b-button>
                            </b-button-group>
                        </template>
                        <template #cell(move_out)="row">
                            <b-button-group size="sm">
                                <b-button :variant="row.item.moveStatusOut === 'in' ? 'success' : 'secondary'"
                                    @click="row.item.moveStatusOut = 'in'">Yes</b-button>
                                <b-button :variant="row.item.moveStatusOut === 'out' ? 'danger' : 'secondary'"
                                    @click="row.item.moveStatusOut = 'out'">No</b-button>
                            </b-button-group>
                        </template>
                        <template #cell(note)="row">
                            <b-form-group class="m-0 mr-1">
                                <input :value="row.item?.note" :placeholder="$t('note')" class="form-control" />
                            </b-form-group>
                        </template>
                    </b-table>
                </div>
            </b-col>
            <b-col cols="12" md="6" class="mt-3">
                <ValidationProvider name="comment" rules="required" v-slot="{ valid, errors }">
                    <b-form-group :label="$t('comment')">
                        <b-form-input type="text" v-model="form.comment" :state="errors[0] ? false : null" />
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                    </b-form-group>
                </ValidationProvider>
            </b-col>
        </b-row>
    </b-tab>
</template>

<script>
export default {
    props: {
        isActive: Boolean,
        iconClass: String,
        title: String,
        items: Array,
        rowClass: {
            type: Function,
            required: true
        },
        fields: Array,
        form: Object
    }
}
</script>
